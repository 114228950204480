import React from 'react'
import { Container } from 'react-bootstrap'
import { Form } from 'react-bootstrap'
import { Button } from 'react-bootstrap'
import { useAuth } from "../contexts/AuthContext";
import { useNavigate } from 'react-router-dom'
import client from "../pbconn.js";
import { useRef } from 'react'

export default function Newsite() {
    const nameRef = useRef();
    const locationRef = useRef();
    const usersSearchRef = useRef();
    const usersRef = useRef();
    const [error, setError] = React.useState("");
    const [loading, setLoading] = React.useState(false);
    const [users, setUsers] = React.useState([]);
    const [addedUsers, setAddedUsers] = React.useState([]);
    const navigate = useNavigate();
    const { currentUser } = useAuth();
    if (!currentUser) {
            navigate("/signin");
    }
    function handleChange(e){
        client.collection("company_users").getList(1,5,{filter:'name~"'+ usersSearchRef.current.value + '"'}).then((users) => {
            setUsers(users.items);
        });
    }
    function addUser(id) {
        const userToAdd = users.filter(user => user.id === id);
        setAddedUsers([...addedUsers, ...userToAdd]);
    }
    function removeUser(id) {
        const newUsers = addedUsers.filter(user => user.id !== id);
        setAddedUsers(newUsers);
    }
    async function createSite(e) {
        console.log(e);
        e.preventDefault();
        //check if submit button was clicked
        if(e.type !== "submit") {
            return;
        }
        setLoading(true);
        var users = ""
        addedUsers.map(user => {
            users = users + user.id + ",";
        });
        //remove last comma
        users = users.slice(0, -1);
        const formData = new FormData();
        formData.append("name", nameRef.current.value);
        formData.append("location", locationRef.current.value);
        formData.append("assignees", users);
        formData.append("company", currentUser.company);
        client.collection("sites").create(formData).then((site) => {
            console.log(site);
            navigate("/sites");
        });
        setLoading(false);
    }
  return (
    <Container>
        <h1>New Site</h1>
        <Form onSubmit={createSite}>
            <Form.Group>
                <Form.Label>Name</Form.Label>
                <Form.Control type="text" ref={nameRef} required />
            </Form.Group>
            <Form.Group>
                <Form.Label>Address</Form.Label>
                <Form.Control type="text" ref={locationRef} required />
            </Form.Group>
            <Form.Group>
                <Form.Label>Assigned Users</Form.Label>
                <Form.Control type="text"  ref={usersSearchRef} onChange={handleChange} />
                <ul>
                    {users.map(user => (
                        <li key={user.id} >
                            {user.name}
                            <Button name="Add" onClick={() => addUser(user.id)}>+</Button>
                        </li>
                    ))}
                </ul>
                <ul>
                    {addedUsers.map(user => (
                        <li key={user.id}>
                            {user.name}
                            <span className='btn btn-danger' onClick={() => removeUser(user.id)}>X</span>
                        </li>
                    ))}
                </ul>
            </Form.Group>
            <Button key={"Submit"} disabled={loading} className="w-100" type="submit">
                Create
            </Button>
        </Form>
    </Container>
  )
}
