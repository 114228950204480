import React from 'react'
import { Card, Image } from 'react-bootstrap'
import { Link, useParams } from 'react-router-dom';
import client from "../pbconn.js";

export default function Sitecard(props) {
    const { site } = props;
    const [photos, setPhotos] = React.useState([]);
    React.useEffect(() => {
        console.log(site.id);
        //get last 4 photos for site
        console.log("loading photos");
        client.collection("photos").getList(1,4,{
            requestKey: 'site-' + site.id,
            filter: 'site="' + site.id + '"',
            sort: '-created',
        }).then((photos) => {
            console.log(photos);
            //set photo url
            photos.items.map(photo => {
                //if dev, use local url
                if(process.env.NODE_ENV === 'production') {
                    photo.url = "https://apiv2.site.photos/api/files/" + photo.collectionId + "/" + photo.id + "/" + photo.photo + "?thumb=100x100";
                } else {
                    photo.url = "http://127.0.0.1:8090/api/files/" + photo.collectionId + "/" + photo.id + "/" + photo.photo + "?thumb=100x100";
                }
            });
            setPhotos(photos.items);
        });
    }, []);
  return (
    <Card className='m-1 shadow' key={site.id}>
        <Card.Body>
            <div className='d-flex flex-md-row mb-3 flex-column' >
                <div className='d-flex flex-column align-items-center'>
            <Card.Title className='mt-auto'>{site.name}</Card.Title>
            <Card.Text className='mb-auto'>
                <p className=''>{site.location}</p>
                <Link className="btn btn-primary m-1" to={`/sites/${site.id}`}>View</Link>
            </Card.Text>
            </div>
            <div className='ms-md-auto'>
                <h2 className='justify-content-center'>Photos</h2>
                <div className='d-flex flex-wrap flex-md-row align-items-center'>
                    {photos.map(photo => (
                        <Image className='p-1' src={photo.url} alt={photo.id} width={100} />
                    ))}
                    {photos.length === 0 && (
                        <p>No photos</p>
                    )}
                    </div>
                </div>
            </div>
            

        </Card.Body>
    </Card>
  )
}
