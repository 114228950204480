import React from 'react'
import { Modal, Form,Button} from 'react-bootstrap'
import { useRef,useState } from 'react'
import { useParams } from 'react-router-dom'
import { useAuth } from '../contexts/AuthContext'
import client from '../pbconn';

function AddClientModal() {
    const [show, setShow] = useState(false);
    const { currentUser } = useAuth();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
    //set refs  
    const first_nameRef = useRef();
    const last_nameRef = useRef();
    const phoneRef = useRef();
    const emailRef = useRef();
    const addressRef = useRef();
    const save = () => {
        //get values from form
        const first_name = first_nameRef.current.value;
        const last_name = last_nameRef.current.value;
        const phone = phoneRef.current.value;
        const email = emailRef.current.value;
        const address = addressRef.current.value;
        //add expense to db
        const formData = new FormData();
        formData.append('first_name', first_name);
        formData.append('last_name', last_name);
        formData.append('phone_number', phone);
        formData.append('email', email);
        formData.append('address', address);
        formData.append('company', currentUser.company);
        client.collection('clients').create(formData).then((res) => {
            console.log(res);
            //clear form
            first_nameRef.current.value = "";
            last_nameRef.current.value = "";
            phoneRef.current.value = "";
            emailRef.current.value = "";
            addressRef.current.value = "";
            //close modal
            handleClose();
        });

    }
  return (
    <>
    <Button variant="primary" onClick={handleShow}>
        Add Client
    </Button>
    <Modal show={show}>
        <Modal.Header closeButton>
            <Modal.Title>Add Client</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Form.Group id="first_name">
                <Form.Label>First Name</Form.Label>
                <Form.Control type="text" required ref={first_nameRef}/>
            </Form.Group>
            <Form.Group id="last_name">
                <Form.Label>Last Name</Form.Label>
                <Form.Control type="text" required ref={last_nameRef}/>
            </Form.Group>
            <Form.Group id="phone">
                <Form.Label>Phone</Form.Label>
                <Form.Control type="text" required ref={phoneRef}/>
            </Form.Group>
            <Form.Group id="email">
                <Form.Label>Email</Form.Label>
                <Form.Control type="text" required ref={emailRef}/>
            </Form.Group>
            <Form.Group id="address">
                <Form.Label>Address</Form.Label>
                <Form.Control type="text" required ref={addressRef}/>
            </Form.Group>
        </Modal.Body>
        <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>Close</Button>
            <Button variant="primary" onClick={save}>Save changes</Button>
        </Modal.Footer>
    </Modal>
    </>
  )
}

export default AddClientModal