import React, { useRef } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Container, Form, Button, Card, Alert, Row, Col } from "react-bootstrap";
import { useAuth } from "../contexts/AuthContext";
import client from "../pbconn.js";


function Uploadphoto() {
    const siteID = useRef();
    const photo = useRef();
    const mphotos = useRef();
    const siteIDm = useRef();
  const [error, setError] = React.useState("");
  const [loading, setLoading] = React.useState(true);
  const navigate = useNavigate();
  const [sites, setSites] = React.useState([]);
    const { currentUser } = useAuth();
    //get site id from url
    const {siteIdUrl} = useParams();
        if (!currentUser) {
            navigate("/signin");
        }
    React.useEffect(() => {
        async function getSite() {
            client.collection("sites").getFullList().then((sites) => {
                console.log(sites);
                setSites(sites);
                setLoading(false);
            });
        }
        getSite();
        }, []);
        //photo multipart upload
        async function uploadPhoto(e) {
            e.preventDefault();
            const formData = new FormData();
            formData.append("photo", photo.current.files[0]);
            formData.append("site", siteID.current.value);
            formData.append("user", currentUser.id);
            formData.append("company", currentUser.company);
            client.collection("photos").create(formData).then((photo) => {
                console.log(photo);
                if (siteIdUrl) {
                    navigate("/sites/" + siteIdUrl);
                }else{
                navigate("/sites");
                }
            });
        }
        //multiple photo multipart upload
        async function uploadMultiplePhotos(e) {
            e.preventDefault();
            //new request per photo
            var i = 0;
            for (const file of mphotos.current.files) {
                const formData = new FormData();
                formData.append("photo", file);
                formData.append("site", siteIDm.current.value);
                formData.append("user", currentUser.id);
                formData.append("company", currentUser.company);
                client.collection("photos").create(formData, {requestKey: i}).then((photo) => {
                    console.log(photo);
                });
                i++;
            }
            if (siteIdUrl) {
                navigate("/sites/" + siteIdUrl);
            }
        }
  return (
    <Container>
        <Row>
            <Col>
                <h1>Upload Photos</h1>
                <Form onSubmit={uploadPhoto}>
                <Form.Label>Site</Form.Label>
                <br/>
                {siteIdUrl && (
                    <Form.Control type="text" defaultValue={siteIdUrl} ref={siteID} readOnly/>
                )
                }
                {!siteIdUrl && (
                    <Form.Select ref={siteID}>
                        {sites.map(site => (
                            <option key={site.id} value={site.id}>{site.name}</option>
                        ))}
                    </Form.Select>
                )
                }
                
                <Form.Group>
                    <Form.Label>Photo</Form.Label>
                    <Form.Control type="file" ref={photo}/>
                </Form.Group>
                <Button disabled={loading} className="w-100" type="submit">
                    Upload Photo
                </Button>
                </Form>
            </Col>
            <Col>
                <h1>Upload Multiple Photos</h1>
                <Form onSubmit={uploadMultiplePhotos}>
                <Form.Label>Site</Form.Label>
                <br/>
                {siteIdUrl && (
                    <Form.Control type="text" defaultValue={siteIdUrl} ref={siteIDm} readOnly/>
                )
                }
                {!siteIdUrl && (
                    <Form.Select ref={siteIDm}>
                        {sites.map(site => (
                            <option key={site.id} value={site.id}>{site.name}</option>
                        ))}
                    </Form.Select>
                )
                }
                <Form.Label>Photos</Form.Label>
                <Form.Control type="file" multiple ref={mphotos}/>
                <Button disabled={loading} className="w-100" type="submit">
                    Upload Photos
                </Button>
                </Form>
            </Col>
        </Row>
    </Container>
  );
}

export default Uploadphoto;
