import React from 'react'
import { Container, Image } from 'react-bootstrap'
import { useParams } from 'react-router-dom';
import client from "../pbconn.js";

export default function Photo() {
    const {photoId} = useParams();
    const [photo, setPhoto] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState("");
    const [editMode, setEditMode] = React.useState(false);
    const notesRef = React.useRef();
    function saveNotes() {
        client.collection("photos").update(photoId, {
            notes: notesRef.current.value
        }).then((photo) => {
            handlePhoto(photo);
            setEditMode(false);
        });
    }
    React.useEffect(() => {
        setLoading(true);
        client.collection("photos").getOne(photoId).then((photo) => {
            handlePhoto(photo);
            setLoading(false);
        });
        }, []);
        function handlePhoto(photo) {
            console.log(photo);
            //if dev, use local url
            if(process.env.NODE_ENV === 'production') {
                photo.url = "https://apiv2.site.photos/api/files/" + photo.collectionId + "/" + photo.id + "/" + photo.photo;
            } else {
                photo.url = "http://127.0.0.1:8090/api/files/" + photo.collectionId + "/" + photo.id + "/" + photo.photo;
            }
            //convert date to local time
            var date = new Date(photo.created);
            photo.created = date.toLocaleString();
            date = new Date(photo.updated);
            photo.updated = date.toLocaleString();
            setPhoto(photo);
        }
  return (
    <Container>
        <h1>Photo</h1>
        <Image src={photo.url} alt={photo.id} width={'100%'} />
        <p>
            Date: {photo.created}
        </p>
        <p>
            Updated: {photo.updated}
        </p>
        <h2>Notes</h2>
        {editMode && (
            <textarea ref={notesRef}>
                {photo.notes}
            </textarea>
        )
        }
        {!editMode && (
            <p>
                {photo.notes}
            </p>
        )
        }
        {editMode && (
            <button onClick={() => saveNotes()}>Save</button>
        )
        }
        {!editMode && (
            <button onClick={() => setEditMode(true)}>Edit</button>
        )
        }
    </Container>
  )
}
