import React from 'react'
import client from '../pbconn'
import { useAuth } from '../contexts/AuthContext'
import { Link, useNavigate } from 'react-router-dom'
import { Button, Container, Modal, Row, Table, Form } from 'react-bootstrap';

export default function Timesheet() {
    const navigate = useNavigate();
    const { currentUser } = useAuth();
    const [timeEntries, setTimeEntries] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState("");
    const [sites, setSites] = React.useState([]);
    const siteRef = React.useRef();
    const hoursRef = React.useRef();
    const dateRef = React.useRef();
    const notesRef = React.useRef();

    if (!currentUser) {
        navigate("/signin");
    }
    function getEntries() {
        setLoading(true);
        client.collection("timesheet").getList(1,25,{sort: '-created', expand: 'site,user'}).then((timeEntries) => {
            console.log(timeEntries);
            setTimeEntries(timeEntries.items);
            setLoading(false);
        });
    }
    React.useEffect(() => {
        getEntries();
        client.collection("sites").getList(1,100,{
            sort: '-updated',
        }).then((sites) => {
            console.log(sites);
            setSites(sites.items);
            setLoading(false);
        });

    }, []);

    function addEntry() {
        console.log("add entry");
        client.collection("timesheet").create({
            company: currentUser.company,
            user: currentUser.id,
            site: siteRef.current.value,
            hours: hoursRef.current.value,
            date: dateRef.current.value,
            notes: notesRef.current.value,
        }).then((timeEntry) => {
            console.log(timeEntry);
            getEntries();
        });
    }
  return (
    <Container>
        <div className="d-flex flex-row">
            <span className='p-1'><h1>Timesheet</h1></span>

            <span className='p-1'><Link className="btn btn-primary m-1" to={`/sites`}>Sites</Link></span>
        </div>
        <div className="d-flex justify-content-between flex-row">
            <p>Add Entry</p>
            <Form.Group>
                <Form.Label>Site</Form.Label>
                <Form.Control as="select" ref={siteRef}>
                    {sites && sites.map(site => (
                        <option key={site.id} value={site.id}>{site.name}</option>
                    ))}
                </Form.Control>
            </Form.Group>
            <Form.Group>
                <Form.Label>Hours</Form.Label>
                <Form.Control type="number" ref={hoursRef} />
            </Form.Group>
            <Form.Group>
                <Form.Label>Date</Form.Label>
                <Form.Control type="date" ref={dateRef}/>
            </Form.Group>
            <Form.Group>
                <Form.Label>Notes</Form.Label>
                <Form.Control type="text" ref={notesRef}/>
            </Form.Group>
            <Button onClick={() => addEntry()}>Add</Button>
        </div>
        <Table striped bordered hover>
            <thead>
                <tr>
                    <th>Site</th>
                    <th>Time</th>
                    <th>Date</th>
                    <th>User</th>
                </tr>
            </thead>
            <tbody>
                {timeEntries.map(timeEntry => (
                    <tr key={timeEntry.id}>
                        <td>{timeEntry.expand.site.name}</td>
                        <td>{timeEntry.hours}</td>
                        <td>{timeEntry.date}</td>
                        <td>{timeEntry.expand.user.name}</td>
                    </tr>
                ))}
            </tbody>
        </Table>

    </Container>
  )
}
