import React from 'react'
import { Container, Row,Image, Col, Card } from 'react-bootstrap'
import { Link, useParams } from 'react-router-dom';
import client from "../pbconn.js";

export default function Photos() {
    const [photos, setPhotos] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState("");
    const {siteId} = useParams();
    const [site, setSite] = React.useState([]);
    React.useEffect(() => {
        setLoading(true);
        if (siteId === undefined) {
            client.collection("photos").getList(1,25,{sort: '-created', expand: 'site,user'}).then((photos) => {
                console.log(photos);
                photos.items.map(photo => {
                    //if dev, use local url
                    if(process.env.NODE_ENV === 'production') {
                        photo.url = "https://apiv2.site.photos/api/files/" + photo.collectionId + "/" + photo.id + "/" + photo.photo + "?thumb=100x100";
                    } else {
                        photo.url = "http://127.0.0.1:8090/api/files/" + photo.collectionId + "/" + photo.id + "/" + photo.photo + "?thumb=100x100";
                    }
                });
                //group photos by date
                var photosByDate = {};
                photos.items.map(photo => {
                    var date = new Date(photo.created);
                    var dateStr = date.toLocaleDateString();
                    var timeStr = date.toLocaleTimeString();
                    photo.dateStr = dateStr;
                    photo.timeStr = timeStr;
                    if (photosByDate[dateStr] === undefined) {
                        photosByDate[dateStr] = [];
                    }
                    photosByDate[dateStr].push(photo);
                });
                setPhotos(photosByDate);
                setLoading(false);
            });
        } else {
            client.collection("photos").getList(1,25, {
                filter: 'site = "' + siteId + '"',
                sort: '-created',
                expand: 'site,user',
            }).then((photos) => {
                photos.items.map(photo => {
                    //if dev, use local url
                    if(process.env.NODE_ENV === 'production') {
                        photo.url = "https://apiv2.site.photos/api/files/" + photo.collectionId + "/" + photo.id + "/" + photo.photo + "?thumb=100x100";
                    } else {
                        photo.url = "http://127.0.0.1:8090/api/files/" + photo.collectionId + "/" + photo.id + "/" + photo.photo + "?thumb=100x100";
                    }
                });
                //group photos by date
                var photosByDate = {};
                photos.items.map(photo => {
                    var date = new Date(photo.created);
                    var dateStr = date.toLocaleDateString();
                    var timeStr = date.toLocaleTimeString();
                    photo.dateStr = dateStr;
                    photo.timeStr = timeStr;
                    if (photosByDate[dateStr] === undefined) {
                        photosByDate[dateStr] = [];
                    }
                    photosByDate[dateStr].push(photo);
                });
                setPhotos(photosByDate);
                client.collection("sites").getOne(siteId).then((site) => {
                    setSite(site);
                });
                setLoading(false);
            });
        }
    }, []);

  return (
    <Container>
        <h1>Photos</h1>
        {siteId && (
            <h2>Site: {site.name}</h2>
        )}
        {loading && <p>Loading...</p>}
        {error && <p>{error}</p>}
        {photos && Object.keys(photos).map(date => (
            <>
            <h2>{date}</h2>
            <Row>
                {photos[date].map(photo => (
                    <Col xs={6} md={4} lg={3} key={photo.id} className='text-center'>
                        <Card className='mb-3'>
                            <Link to={`/photo/${photo.id}`}>
                                <Card.Body>
                                    <Image src={photo.url} width={'100%'} />
                                    <p>{photo.expand.site.name}</p>
                                    <p>{photo.timeStr}-{photo.expand.user.name}</p>
                                </Card.Body> 
                            </Link>
                        </Card>
                    </Col>
                ))}
            </Row>
            </>
        ))}
    </Container>
  )
}
