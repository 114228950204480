import React from "react";
import Signup from "./Signup";
import { AuthProvider } from "../contexts/AuthContext";
import {Routes, Route, Navigate} from "react-router-dom";
import ProtectedRoute from "./ProtectedRoute";
import Signin from "./Signin";
import Sites from "./Sites";
import Site from "./Site";
import Uploadphoto from "./Uploadphoto";
import {useAuth} from "../contexts/AuthContext";
import Newsite from "./Newsite";
import SiteExpenses from "./SiteExpenses";
import Sitecamera from "./Sitecamera";
import Photos from "./Photos";
import Photo from "./Photo";
import Timesheet from "./Timesheet";
import Sidebar from "./Sidebar";
import Clients from "./Clients";

function Outlog() {
  const { logout } = useAuth();
  logout();
  return <Navigate to="/" />;
}

function App() {
  return (
      
    <AuthProvider>
      <Sidebar>
      <Routes>
        <Route path="/" element={<Signin />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/logout" element={< Outlog />} />
        <Route path="/sites">
          <Route path="/sites" element={<Sites />} />
          <Route path="new" element={<Newsite/>} />
          <Route path=":siteId" element={<Site />} />
          <Route path=":siteId/takePhoto" element={<Sitecamera />} />
          <Route path=":siteId/expenses" element={<SiteExpenses />} />
          <Route path=":siteId/photos" element={<Photos />} />
        </Route>
        <Route path="/clients" element={<Clients />} />
        <Route path="/photos" element={<Photos/>} />
        <Route path="/photo/:photoId" element={<Photo />} />
        <Route path="/upload/photo/site/:siteIdUrl" element={<Uploadphoto/>} />
        <Route path="/upload/photo/" element={<Uploadphoto/>} />
        <Route path="/signin" element={<Signin />} />
        <Route path="/timesheet" element={<Timesheet />} />
      </Routes>
      </Sidebar>
    </AuthProvider>
  );
}

export default App;
