import React, { useRef } from "react";
import { Link, useNavigate, useBeforeUnload } from "react-router-dom";
import { Container, Form, Button, Card, Alert, Row } from "react-bootstrap";
import { useAuth } from "../contexts/AuthContext";
import client from "../pbconn.js";
import Sitecard from "./Sitecard";


function Sites() {
  const { signup } = useAuth();
  const [error, setError] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const navigate = useNavigate();
  const [sites, setSites] = React.useState([]);
    const { currentUser } = useAuth();

        if (!currentUser) {
            navigate("/signin");
        }
    React.useEffect(() => {
        //check if user is logged in
        console.log("loading sites");
        async function getSites() {
            client.collection("sites").getList(1,100,{
                sort: '-updated',
            }).then((sites) => {
                console.log(sites);
                setSites(sites.items);
                setLoading(false);
            });
        }
        getSites();
        client.collection("sites").subscribe('*', (sites) => {
            console.log("site updated");
            getSites();
        });
        return () => {
            console.log("unmounting");
            client.collection("sites").unsubscribe();
        };
        }, []);
  return (<div className="d-flex">
    <div className="m-2 p-2 flex-grow-1">
        <div className="d-flex justify-content-between flex-row">
        <h1>My Sites</h1>
        </div>
        <div >
            {sites.map(site => (
                <Sitecard key={site.id} site={site} />
            ))}
        </div>
    </div>
    </div>
  );
}

export default Sites;
