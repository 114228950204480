import React from 'react'
import { Link } from 'react-router-dom'
import { useAuth } from '../contexts/AuthContext'
import { Nav, Navbar } from 'react-bootstrap';

export default function Sidebar({children}) {
    const { currentUser } = useAuth();
    if (!currentUser) {
        return (<div>{children}</div>);
    }
  return (
    <div className="d-flex flex-column flex-md-row w-100 ">
        <div className='d-none d-md-flex flex-row flex-md-column' style={{width:'200px'}}>
            {currentUser &&(
                <div className="p-1">
                    <div>{currentUser.company.name}</div>
                    <div>{currentUser.name}</div>
                    <div>{currentUser.email}</div>
                </div>
            )}
            <Link className="btn btn-primary m-1" to={`/clients`}>Clients</Link>
            <Link className="btn btn-primary m-1" to={`/sites`}>Sites</Link>
            <Link className="btn btn-primary m-1" to={`/upload/photo`}>Upload Photo</Link>
            <Link className="btn btn-primary m-1" to={`/photos`}>View Photos</Link>
            <Link className="btn btn-primary m-1" to={`/timesheet`}>Timesheet</Link>
            {currentUser && currentUser.role === 'admin' && (
                <Link className="btn btn-primary m-1" to={`/admin`}>Admin</Link>
            )
            }
            <Link className="btn btn-primary m-1" to={`/logout`}>Logout</Link>
        </div>
        <Navbar bg="light" expand="lg" className='d-flex flex-row flex-md-column d-md-none ms-2 me-2'>
            
            <Navbar.Brand>Site Photos</Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="flex-column">
                    <Link className="btn btn-primary m-1" to={`/clients`}>Clients</Link>
                    <Link className="btn btn-primary m-1" to={`/sites`}>Sites</Link>
                    <Link className="btn btn-primary m-1" to={`/upload/photo`}>Upload Photo</Link>
                    <Link className="btn btn-primary m-1" to={`/photos`}>View Photos</Link>
                    <Link className="btn btn-primary m-1" to={`/timesheet`}>Timesheet</Link>
                    {currentUser && currentUser.role === 'admin' && (
                        <Link className="btn btn-primary m-1" to={`/admin`}>Company Admin</Link>
                    )
                    }
                    <Link className="btn btn-primary m-1" to={`/logout`}>Logout</Link>

                </Nav>
            </Navbar.Collapse>
        </Navbar>
        <div className="m-2 p-2 flex-grow-1">
            {children}
        </div>
    </div>
  )
}
