import React from 'react'
import { Table } from 'react-bootstrap'
import client from '../pbconn'
import AddClientModal from './AddClientModal';

export default function Clients() {
  const [clients, setClients] = React.useState([])
  function getClients() {
    client.collection('clients').getFullList().then((clients) => {
      console.log(clients)
      setClients(clients)
    });
  }
  React.useEffect(() => {
    document.title = 'Clients'
    getClients();
    client.collection('clients').subscribe("*",getClients)
  }, [])
  return (
    <div>
      <div className="d-flex flex-row">
        <h1 className="clients">Clients</h1>
        <AddClientModal />
      </div>
    <Table striped bordered hover>
      <thead>
        <tr>
          <th>Client Name</th>
          <th>Phone</th>
          <th>Email</th>
          <th>Address</th>
        </tr>
      </thead>
      <tbody>
        {clients.map((client) => (
          <tr key={client.id}>
            <td>{client.first_name}, {client.last_name}</td>
            <td>{client.phone_number}</td>
            <td>{client.email}</td>
            <td>{client.address}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  </div>
  )
}
