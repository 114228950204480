import React from 'react'
import { Modal, Form,Button} from 'react-bootstrap'
import { useRef,useState } from 'react'
import { useParams } from 'react-router-dom'
import { useAuth } from '../contexts/AuthContext'
import client from '../pbconn';

function AddExpenseModal() {
    //get siteId from url
    const {siteId} = useParams()
    const [show, setShow] = useState(false);
    const { currentUser } = useAuth();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
    //set refs  
    const amountRef = useRef();
    const notesRef = useRef();
    const receiptRef = useRef();
    React.useEffect(() => {
        console.log("siteId: " + siteId);
    }, [])

    const save = () => {
        //get values from form
        const amount = amountRef.current.value;
        const notes = notesRef.current.value;
        const receipt = receiptRef.current.files[0];
        //add expense to db
        const formData = new FormData();
        formData.append('site', siteId);
        formData.append('user', currentUser.id );
        formData.append('company', currentUser.company );
        formData.append('amount', amount);
        formData.append('notes', notes);
        formData.append('photo', receipt);
        client.collection('expenses').create(formData).then((res) => {
            console.log(res);
            //clear form
            amountRef.current.value = "";
            notesRef.current.value = "";
            receiptRef.current.value = "";
            //close modal
            handleClose();
        });

    }
  return (
    <>
    <Button variant="primary" onClick={handleShow}>
        Add Expense
    </Button>
    <Modal show={show}>
        <Modal.Header closeButton>
            <Modal.Title>Add Expense</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Form.Group id="expenseAmount">
                <Form.Label>Amount</Form.Label>
                <Form.Control type="text" required ref={amountRef}/>
            </Form.Group>
            <Form.Group id="expenseNotes">
                <Form.Label>Notes</Form.Label>
                <Form.Control type="text" required ref={notesRef}/>
            </Form.Group>
            <Form.Group id="expenseReceipt">
                <Form.Label>Receipt</Form.Label>
                <Form.Control type="file" required ref={receiptRef} />
            </Form.Group>
        </Modal.Body>
        <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>Close</Button>
            <Button variant="primary" onClick={save}>Save changes</Button>
        </Modal.Footer>
    </Modal>
    </>
  )
}

export default AddExpenseModal