import React from 'react'
import { Routes, Route, Navigate, useParams, Link } from 'react-router-dom'
import { AuthProvider } from '../contexts/AuthContext'
import client from '../pbconn'
import { Button, Container, Table} from 'react-bootstrap'
import AddExpenseModal from './AddExpenseModal'

function SiteExpenses() {
    //get siteId from url
    const {siteId} = useParams()
    const [expenses, setExpenses] = React.useState([])
    const [showModal, setShowModal] = React.useState(false)
    console.log(siteId)
    React.useEffect(() => {
        function getExpenses() {
            client.collection('expenses').getList(1,50,{filter:'site="' + siteId + '"'}).then((res) => {
                setExpenses(res.items)
            }  );
        }
        getExpenses()
        client.collection('expenses').subscribe('*', (res) => {
            console.log('expenses updated')
            getExpenses()
        })
        return () => {
            console.log('unmounting')
            client.collection('expenses').unsubscribe()
        }
    }, [])
  return (
    <Container>
        <Link to={`/sites/${siteId}`}>Back to Site</Link>
    <h1>Expenses</h1>
    <AddExpenseModal showModal={showModal} setShowModal={setShowModal} />
        <Table striped bordered hover>
            <thead>
                <tr>
                    <th>Expense</th>
                    <th>Amount</th>
                    <th>Notes</th>
                    <th>Receipt</th>
                </tr>
            </thead>
            <tbody>
            {expenses.map((expense) => {
                    return (
                        <tr key={expense.id}>
                            <td>{expense.id}</td>
                            <td>{expense.amount}</td>
                            <td>{expense.notes}</td>
                            <td>image</td>
                        </tr>
                    )
                })}
            </tbody>
        </Table>
    </Container>
  )
}

export default SiteExpenses