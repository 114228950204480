import React, { useContext, useEffect } from "react";
import client from "../pbconn";

const AuthContext = React.createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = React.useState();

  async function signup(username, password, companyName) {
    //add company to company collection
    var company = await client.collection("companies").create({
      password: password,
      passwordConfirm: password,
      name: companyName
    });
    //auth as company
    await client.collection("companies").authWithPassword(company.username, password);
    //add user to user collection
    var user = await client.collection("company_users").create({
      email: username,
      password: password,
      passwordConfirm: password,
      company: company.id,
      role: "admin"
    });
    //auth as user
    await client.collection("company_users").authWithPassword(username, password);
    return user;
  }

  async function signin(username, password) {
    return client.collection("company_users").authWithPassword(username, password);
  }

  async function logout() {
    return client.authStore.clear();
  }

  useEffect(() => {
    const unsubscribe = client.authStore.onChange(user => {
      console.log(client.authStore.model);
      setCurrentUser(client.authStore.model);
    }, true);
    return unsubscribe;
  }, []);
  const value = {
    currentUser,
    signup,
    signin,
    logout
  };
  return (<AuthContext.Provider value={value}>{children}</AuthContext.Provider>);
}