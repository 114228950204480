import React, { useRef } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Container, Form, Button, Card, Alert, Row, Col, Image } from "react-bootstrap";
import { useAuth } from "../contexts/AuthContext";
import client from "../pbconn.js";



function Sites() {
    //get params from url
    const {siteId} = useParams();
  const { signup } = useAuth();
  const [error, setError] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const navigate = useNavigate();
  const [site, setSite] = React.useState([]);
  const [photos, setPhotos] = React.useState([]);
    const { currentUser } = useAuth();

        if (!currentUser) {
            navigate("/signin");
        }
    React.useEffect(() => {
        //check if user is logged in
        async function getSite() {
            client.collection("sites").getOne( siteId ).then((site) => {
                console.log(site);
                setSite(site);
                setLoading(false);
            });
            //get photo list
            client.collection("photos").getList(1,4,{
                filter: 'site = "' + siteId + '"',
                sort: '-created',
            }).then((photos) => {
                console.log(photos);
                //set photo url
                photos.items.map(photo => {
                    //if dev, use local url
                    if(process.env.NODE_ENV === 'production') {
                        photo.url = "https://apiv2.site.photos/api/files/" + photo.collectionId + "/" + photo.id + "/" + photo.photo + "?thumb=250x250";
                    } else {
                        photo.url = "http://127.0.0.1:8090/api/files/" + photo.collectionId + "/" + photo.id + "/" + photo.photo + "?thumb=250x250";
                    }
                });
                setPhotos(photos.items);
            });
        }
        getSite();
        client.collection("sites").subscribe(siteId, (sites) => {
            console.log("site updated");
            getSite();
        });

        return () => {
            console.log("unmounting");
            client.collection("sites").unsubscribe();
        };
        }, []);
  return (
    <Container>
        <Link to={`/sites`}>Back to Sites</Link>
        <Row xs={1} md={2} className="g-4">
            <Col>
            <h1>Site { site.name }</h1>
            <p>Site ID: { site.id }</p>
            <p>Site Name: { site.name }</p>
            <p>Site Address: { site.location }</p>
            <p>Site Description: { site.description }</p>
            <p>Site Public: { site.companyPublic }</p>
            <p>Site Created: { site.created }</p>
            <p>Site Updated: { site.updated }</p>
            <Link className="btn btn-primary m-1" to={`/sites/${site.id}/edit`}>Edit Site</Link>
            <Link className="btn btn-primary m-1" to={`/upload/photo/site/${site.id}`}>Upload Photo</Link>
            <Link className="btn btn-primary m-1" to={`/sites/${site.id}/expenses`}>Expenses</Link>
            <Link className="btn btn-primary m-1" to={`/sites/${site.id}/takePhoto`}>Take Photo</Link>
            <Link className="btn btn-primary m-1" to={`/sites/${site.id}/photos`}>View Photos</Link>
            </Col>
            <Col>
            <div className="d-inline-flex flex-wrap p-2">
            {photos.map(photo => (
                <Card key={photo.id} >
                    <Card.Img variant="top" src={photo.url}/>
                </Card>
            ))}
            </div>
            </Col>
        </Row>
    </Container>
  );
}

export default Sites;
