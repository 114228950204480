import React from 'react'
import { useAuth } from "../contexts/AuthContext";
import { useParams } from 'react-router-dom'
import { useRef } from 'react'
import client from "../pbconn.js";
import { Camera } from 'react-camera-pro';
import { Button, Col, Container, Image, Row } from 'react-bootstrap';
import { Buffer } from 'buffer';

export default function Sitecamera() {
    const {currentUser} = useAuth();
    const {siteId} = useParams();
    const [error, setError] = React.useState("");
    const [loading, setLoading] = React.useState(false);
    const cameraRef = useRef(null);
    const [image, setImage] = React.useState(null);
    async function takePhoto() {
        setLoading(true);
        var photo = await cameraRef.current.takePhoto();
        //build jpeg blob from base64
        //random filename
        console.log(photo);
        var base64 = photo.split(',')[1];
        var filename = Math.random().toString(36).substring(7);
        var file = new File([base64], filename, {type: "image/jpeg"});
        setImage(image);
        console.log(file);
        var formData = new FormData();
        formData.append("photo", new Blob([Buffer.from(base64,"base64")]), filename + ".jpg");
        formData.append("site", siteId);
        formData.append("user", currentUser.id);
        formData.append("company", currentUser.company);
        client.collection("photos").create(formData).then((photo) => {
            console.log(photo);
            setLoading(false);
        });
    }

  return (
    <>
    <Container className="" style={{ overflow: "hidden" }}>
        <Row>
        <Camera ref={cameraRef} facingMode='enviroment' aspectRatio={16 / 9} />
        </Row>
        <Row style={{bottom:'0', width:"100%", position: "absolute"}} className=''>
            <Col className='text-center'>
                <Button disabled={loading} style={{ "zIndex": "1", bottom: "0"}} className='btn btn-primary p-3' onClick={() => takePhoto()}>Take photo</Button>
            </Col>
            <Col className='text-center'>
                <Button disabled={loading} style={{ "zIndex": "1", bottom: "0"}} className='btn btn-primary p-3' onClick={() => cameraRef.current.switchCamera()}>Switch Camera</Button>
            </Col>
        </Row>
        <Image src={image} />

    </Container>
    </>
  )
}
